import React, { useEffect } from 'react';
import { useState } from 'react';
import { useRecoilState } from 'recoil';
import BBCard from '@/base_blocks/bbcard';
import BBLink from '@/base_blocks/bblink';
import BBText from '@/base_blocks/bbtext';
import Footer from '@/components/footer';
import HeadHtml from '@/components/head_html';
import NavbarMain from '@/components/navbars/navbar_main';
import { useStateManagerContext } from '@/components/state_manager/context';
import { stateUser } from '@/states/auth';
import type { NextPage } from 'next';
import Routes from 'routes';
import { isLoggedIn } from 'utils/auth';

const Home: NextPage = () => {
  const stateManagerContext = useStateManagerContext();
  const [user] = useRecoilState(stateUser);
  const [componentUserCard, setComponentUserCard] = useState<React.ReactElement | null>(null);

  useEffect(() => {
    stateManagerContext.updateStateFunctions.updateUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLoggedIn(user) && !!user) {
      setComponentUserCard(
        <BBCard>
          <BBCard.Header>
            <BBText size="xxlarge">User Profile</BBText>
          </BBCard.Header>
          <BBCard.Body>
            <BBText>
              {user.first_name} {user.last_name}
            </BBText>
            <BBText>{user.email}</BBText>
          </BBCard.Body>
        </BBCard>
      );
    }
  }, [user]);

  const strWelcome = 'welcome :) read ';
  const strBlog = 'my blog :)';
  return (
    <div>
      <HeadHtml title="Home" />
      <NavbarMain />
      <div className="main">
        <BBCard>
          <BBCard.Header>
            <BBText size="xxxlarge">Welcome to Dan&apos;s Blog</BBText>
          </BBCard.Header>
          <BBCard.Body>
            <BBText size="large">
              {strWelcome}
              <BBLink asSpan size="large" href={Routes.routeBlogResults()}>
                {strBlog}
              </BBLink>
            </BBText>
          </BBCard.Body>
        </BBCard>
        {componentUserCard}
      </div>
      <Footer />
    </div>
  );
};

export default Home;
